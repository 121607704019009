import { useMsal } from '@azure/msal-react';

export function isAdmin(): boolean {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { instance } = useMsal();

    const account = instance.getActiveAccount();

    const adminRole = account?.idTokenClaims?.roles?.filter((x) => x == 'admin');

    console.log('IsAdmin: ', adminRole?.length);

    return (adminRole && adminRole.length > 0) ?? false;
}
